import React from "react"

const Car = () => {
  return (
    <div id="car">
      <div className="scene">
        <div className="shape cuboid-1 frame-bottom-1">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-2 frame-bottom-6">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-3 frame-bottom-2">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-4 frame-bottom-3">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-5 frame-bottom-7">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-6 frame-bottom-5">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-7 frame-bottom-4">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-8 frame-top-6">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-9 frame-top-2">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-10 frame-top-1">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-11 frame-top-8">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-12 frame-top-4">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-13 frame-top-3">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-14 frame-top-5">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-15 frame-top-7">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-16 frame-top-9">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-17 body-1">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-18 cub-1">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-19 body-2">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-20 body-3">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-21 glass-1">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-22 glass-4">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-23 glass-3">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-24 glass-2">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-25 glass-5">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-26 glass-6">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-27 wheel-3">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-28 wheel-2">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-29 wheel-4">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-30 wheel-1">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-31 rim-3">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-32 cub-7">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-33 rim-4">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-34 rim-1">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-35 rim-2">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-36 cub-2">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-37 cub-3">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-38 cub-4">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-39 cub-5">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-40 cub-6">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
        <div className="shape cuboid-41 cub-8">
          <div className="face ft">
            <div className="photon-shader"></div>
          </div>
          <div className="face bk">
            <div className="photon-shader"></div>
          </div>
          <div className="face rt">
            <div className="photon-shader"></div>
          </div>
          <div className="face lt">
            <div className="photon-shader"></div>
          </div>
          <div className="face bm">
            <div className="photon-shader"></div>
          </div>
          <div className="face tp">
            <div className="photon-shader"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Car;